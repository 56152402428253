import React, {Suspense} from 'react';
import './styles/style.min.css';

// import {
//   BrowserView
// } from "react-device-detect";

import {
  HashRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './index.css';

import ProjectGrid from './pages/projects';
import HeaderNav from './components/header-nav';
import Footer from './components/footer';
import Loading from './components/loading';
// import AnimatedCursor from './components/cursor';

function App() {
  return (
    <Router hashType={'noslash'} style={{overflow: 'auto'}}>
      <Suspense fallback={<Loading/>}>
      <HeaderNav/>
        <main id="main">
          <Routes>
            <Route exact path="/" element={<ProjectGrid/>}>
            </Route>
          </Routes>
        </main>
        <Footer/>
        {/* <BrowserView>
            <AnimatedCursor />
        </BrowserView> */}
      </Suspense>
    </Router>
  );
}

export default App;
