import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import arrowLink from '../assets/arrow-link.svg'

const HeroWrapper = (props) => {
  // const [width, setWidth] = useState(0);
  const [linkState, setLinkState] = useState(false);

  useEffect(() => {
    // setWidth(Math.floor(Math.random() * 1 + 5))
    if (typeof props.path != "undefined" ) {
      setLinkState(true);
    }

  }, [props.path]);

  return (
    <article className="gallery-unique-item m-xs-bxs m-md-bm">
          <HeroItem data={props} linkState={linkState}/>
    </article>
  )
}

const HeroItem = ({data, linkState}) => {
  return(
    <>
    {
      data.multipleImages ? (
        <MultiMedia data={data} linkState={linkState}/>
      ) : (
        <Media data={data} linkState={linkState}/>
      )
   }
    </>
  );
}

const MultiMedia = ({data, linkState}) => {
  return(
        <>
          {data.multimedia.map(image => (
            <figure className='p-xs-bxs'>
              <img className="max-xs-100 project-media" src={image.fields.file.url} alt={image.fields.description} />
            </figure>
          ))}
            { data.title ? (
            <ProjectInfo linkState={linkState} data={data}/>
            ) : (
              <></>
            ) }
        </>
  )
}


const Media = ({data, linkState}) => {
  return(
    <>
      {data.media.fields.file.contentType === "video/mp4" ? ( 
        <>
          <div className={`p-xs-bxs`} dangerouslySetInnerHTML={{ __html: `
                        <video class=${`max-xs-100`} autoplay loop muted defaultMuted playsinline  oncontextmenu="return false;" preload="auto">
                        <source src=${data.media.fields.file.url} type=${data.media.fields.file.contentType} />
                    </video>
          ` }}></div>
            { data.title ? (
            <ProjectInfo linkState={linkState} data={data}/>
            ) : (
              <></>
            ) }
        </>
    ) : (
        <div>
            <figure  className='p-xs-bxs'>
              <img className="max-xs-100 project-media" src={data.media.fields.file.url} alt={data.media.fields.description} />
            </figure>
            { data.title ? (
            <ProjectInfo linkState={linkState} data={data}/>
            ) : (
              <></>
            ) }
        </div>
    )}
    </>
  )
}

const ProjectInfo = ({data, linkState}) => {
  return(
    <>
      <div className="flex-xs-parent flex-xs-horizontal flex-xs-align-start">
        {/* { linkState ? ( <img className="link-arrow" src={arrow} alt="link arrow" /> ) : ('') } */}
            <h2 className="uppercase project-title">{data.title}</h2>
            { linkState ? ( <h2 className="m-xs-lxs uppercase project-title">{"("}<a className="project-link" rel="noreferrer" target='_blank' href={data.path}><span>LINK<img className="link-arrow" src={arrowLink} alt="link arrow" /></span></a>{")"}</h2> ) : ('') }
        </div>
        <h3 className="m-xs-txxs m-xs-bxs">{data.projectType}</h3>
    </>
  )

}


export default HeroWrapper

  