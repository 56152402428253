import React from 'react';
import arrow from '../assets/arrow2.svg'
import logoIcon from '../assets/logo-renaissance-g.svg'


const HeaderNav = ({children}) => {
    return ( 
        <header className='p-xs-bm p-xs-txs p-md-ts'>
            <section className='header-unique-grid m-xs-lxs m-xs-rxs m-md-ls m-md-rs m-xs-bs'>
                <h1 className='col-xs-11 col-md-4' style={{maxWidth: "300px"}}><span className='uppercase'><span className="name-color">GUSTAV BODIN</span> is a multidisciplinary designer focusing on brand building. Currently based in Stockholm&nbsp;Sweden.</span>
                </h1>
                <div className='col-xs-9 col-md-4 offset-md-5 col-lg-3' style={{maxWidth: "300px"}}>
                    <h2><a className='inline-text-link hooverable' target='_blank' rel='noopener noreferrer' href='https://www.gilion.com/' ><span className='uppercase'>Gilion</span></a></h2>
                    <h2><span className='uppercase opacity-60'>Brand Design Director</span></h2>
                    <h2 className='opacity-60'><span className='uppercase'>April 2023 </span><img className="span-arrow" src={arrow} alt="link arrow" /><span className='uppercase'>  present</span></h2>
                </div>
                <div className="col-xs-9 col-md-4 offset-md-9">
                    <h2><span className='uppercase'>Previous experience</span></h2>
                    <h2><a className='inline-text-link hooverable' target='_blank' rel='noopener noreferrer' href='https://einride.tech/' ><span className='uppercase opacity-60'>Einride</span></a><span className='uppercase opacity-60'>, </span><a className='inline-text-link hooverable' target='_blank' rel='noopener noreferrer' href='https://itsitchy.com/' ><span className='uppercase opacity-60'>Itchy</span></a><span className='uppercase opacity-60'>, </span><a className='inline-text-link hooverable' target='_blank' rel='noopener noreferrer' href='https://noaignite.se/'><span className='uppercase opacity-60'>NoA Ignite</span></a></h2>
                </div>
            </section>
            <section className='header-unique-grid m-xs-lxs m-xs-rxs m-md-ls m-md-rs m-xs-bs'>
                <div className='col-xs-10 col-sm-11 col-md-4' style={{maxWidth: "270px"}}>
                    <div className='p-xs-bxs'>
                        <h2><span className='uppercase'>Recogntion</span></h2>
                        <h2><span className='uppercase opacity-60'>AWWWARDS, World Brand Design Society, Pentawards, ADDY'S, Young Glory, Valedictorian</span></h2>
                    </div>
                    <div className='p-xs-bs'>
                        <h2><span className='uppercase'>Clients</span></h2>
                        <h2><span className='uppercase opacity-60'>Moet &amp; Chandon, DAY—INT, UBU, Patriksson, Gina Tricot, ACO, Tom Wood</span></h2>
                    </div>
                    <div className='p-xs-bs'>
                        <h2><span className='uppercase'>Contact</span></h2> 
                        <h2><a className='inline-text-link hooverable' href='mailto:hi@gustavbodin.com?Subject=Hi!' target='_top' rel='noopener noreferrer'><span className='uppercase opacity-60'>email</span></a><span className='uppercase opacity-60'>, </span><a className='inline-text-link hooverable' href='https://www.linkedin.com/in/gustavbodin/' target='_blank' rel='noopener noreferrer'><span className='uppercase opacity-60'>Linkedin</span></a><span className='uppercase opacity-60'>, </span><a className='inline-text-link hooverable' href='https://www.are.na/gustav-bodin' target='_blank' rel='noopener noreferrer'><span className='uppercase opacity-60'>ARE.NA</span></a></h2>
                    </div>
                </div>
            </section>
        </header>
        // <header>
        //     <div className='logo-wrapper'>
        //         <img className="icon-logo" src={logoIcon} alt="link arrow"/>
        //         <h1 className='name-logo'>Gustav Bodin</h1>
        //         <h1 className='name-logo opacity-40'>Brand Director</h1>
        //         <h1 className='name-logo opacity-40'><a className='company-link' target='_blank' rel='noopener noreferrer' href='https://www.gilion.com/' >Gilion</a></h1>
        //     </div>
        // </header>
    );
}

// const Nav = ({children}) => {
// 	return (
// 	<nav className='nav'>
// 		<ul className='flex-xs-parent'>
// 			{children.map((o,i)=>(<li className='nav-tem text-uppercase' key={i}>{o}</li>))}
// 		</ul>
// 	</nav>
// 	);
// }

export default HeaderNav;