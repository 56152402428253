import React from 'react';
import ScrollToTop from './back_to_top'

const Footer = () => {
    return(
        <footer className='p-xs-txs p-md-ts p-xs-bxs'>
        <section className='header-unique-grid m-xs-lxs m-xs-rxs m-md-ls m-md-rs m-xs-bxl'>
            <div className='col-xs-9 col-md-4 offset-md-1 col-lg-2' style={{maxWidth: "300px"}}>
                <h2><span className='uppercase  '>Contact</span></h2> 
                <h2 className=''><a className='' href='mailto:hi@gustavbodin.com?Subject=Hi!' target='_top' rel='noopener noreferrer'><span className='uppercase  opacity-60'>email</span></a><span className='uppercase  opacity-60'>, </span><a className='' href='https://www.linkedin.com/in/gustavbodin/' target='_blank' rel='noopener noreferrer'><span className='uppercase  opacity-60'>Linkedin</span></a><span className='uppercase   opacity-60'>, </span><a className='' href='https://www.are.na/gustav-bodin' target='_blank' rel='noopener noreferrer'><span className='uppercase   opacity-60'>ARE.NA</span></a></h2>
            </div>
        </section>
        <section className='header-unique-grid m-xs-lxs m-xs-rxs m-md-ls m-md-rs'>
            <div className='col-xs-7 col-sm-11 col-md-4 col-lg-3' style={{maxWidth: "300px"}}>
                <div className='p-xs-bxs'>
                     <p className="">Copyright © Gustav Bodin 2024. Created with <a className='' target='_blank' rel='noopener noreferrer' href='https://reactjs.org/' ><span className=''>React</span></a> and my own <a className='' target='_blank' rel='noopener noreferrer' href='https://mannenpag.github.io/sass-library/' ><span className=''>Sass&nbsp;Library</span></a></p>
                </div>
            </div>
            <div className='col-xs-9 col-md-4 offset-md-5 col-lg-2 m-xs-bxs' style={{maxWidth: "300px"}}>
                <ScrollToTop/>
            </div>
        </section>
    </footer>
    );
}

export default Footer;