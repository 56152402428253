import React, {Component} from 'react';

const wordList = ['Loading', 'Gustav', 'Bodin', 'Portfolio'];

// const thing = () => {
//     let [a,aS] = useReducer((s,a)=>{return [...s,...a];},{});
//     aS({type:true});
// }

class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 0
        };
    }

    increment() {
        if (this.state.count < wordList.length) {
            this.setState(prevState => ({
                count: prevState.count + 1
            }));
        } else {
            this.setState({
                count: 0
            });
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.increment(), 300);
      }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        let index = this.state.count
        return(
            <section className='loading-screen'>
                <h3 className='uppercase'>{wordList[index]}</h3>
            </section>
        );
    }
}

export default Loading;