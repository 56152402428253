import React, { Component } from 'react';
import arrowUp from '../assets/arrow-up.svg'

// https://www.coderomeos.org/scroll-to-top-of-the-page-a-simple-react-component

export default class ScrollToTop extends Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            var scrollComponent = this;
            document.addEventListener('scroll', function(e) {
                scrollComponent.toggleVisibility();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleVisibility() {
        if (this._isMounted) {
            if (window.pageYOffset > 500) {
                this.setState({
                isVisible: true
                });
            } else {
                this.setState({
                isVisible: false
                });
            }
        }
    }

    backToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }

    render() {
        return(

                <h2 onClick={() => this.backToTop()} className='back-to-top'><span className='uppercase'><img className="span-arrow-up" src={arrowUp} alt="link arrow"/>Back to top</span></h2>

        );
    }
}
