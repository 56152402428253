import React from 'react'
import * as contentful from 'contentful'
import HeroProject from '../components/hero';
// import { render } from '@testing-library/react';

// const ProjectGrid = () => {
//     const [data, setData] = useState(null);

//     useEffect(() => {
//       contentful.createClient({
//         space: '00b287etu3cx',
//         accessToken: 'lLU_zajnIvKqDWiuHLgVadPpfbZof-w27MpDFp0Jh-o'
//       })
//       .then((client) => client.getEntries())
//       then
//       //  console.log(client.getEntries)

//     })

//     return(
//       <div>
//           { this.state.posts.map(({fields}, i) =>
//             <HeroProject key={i} {...fields} />
//          )}
//       </div>
//     )
// }

class ProjectGrid extends React.Component {
    state = {
      posts: []
    }

    client = contentful.createClient({
      space: '4eyt0fb2u3ih',
      accessToken: '9f28bgKChwy0HFW81kGMobPVVl57DbQRa3ZgWaUxFwk'
    })

    componentDidMount() {
      this.fetchPosts().then(this.setPosts);
    }

    fetchPosts = () => this.client.getEntries()

    setPosts = response => {
      this.setState({
        posts: response.items
      })
    }

    render() {
      return (
        <section className='gallery-unique-wrapper'>
            {/* { this.state.posts.map(({fields}, i) =>
              <pre key={i}>{JSON.stringify(fields, null, 2)}</pre>
            )} */}
            { this.state.posts.map(({fields}, i) =>
                <HeroProject key={i} {...fields} />
            )}
        </section>
      )
    }
  }

  export default ProjectGrid